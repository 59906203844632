export default {
  props: ['value', 'propsOptions', 'size'],
  model: {
    prop: 'value',
    event: 'change'
  },
  data () {
    return {
      defaultValue: this.value, // 表单的初始值（与current的区别是，在表单调用reset方法的时候不清空初始值）
      currentValue: this.value // 表单控件当前值，有时候用到v-model或类型转换
    }
  },
  computed: {
    mode () {
      const { formType } = this.propsOptions
      switch (formType) {
        case 'select':
        case 'tagsSelect':
        case 'multipleSelect':
          return formType.substring(0, formType.length - 6)
        default:
          return formType
      }
    },
    labelKey () {
      return this.propsOptions.labelKey || 'label'
    },
    valueKey () {
      return this.propsOptions.valueKey || 'value'
    },
    extraLabelKey () {
      return this.propsOptions.extraLabelKey || 'label'
    }
  },
  watch: {
    value: {
      handler (value, odlVal) {
        this.currentValue = value
        this.handleValueChange(value, odlVal)
      },
      deep: true
    }
  },
  methods: {
    /**
     * 表单控件的值发生改变时触发
     * @param value
     */
    handleValueChange (value, odlVal) {
      const { params, labelParams, options } = this.propsOptions
      let labelValue
      // 根据value找到对应的label，目前只支持单选
      if (labelParams && !Array.isArray(value)) {
        labelValue = this.mapValueToLabel(value, options, this.labelKey, this.valueKey)
      }
      this.$emit('on-value-change', {
        params,
        value: Array.isArray(value) ? this.$ax.tools.formatValue(value, 'toStr') : value,
        labelParams,
        labelValue,
        formItem: this.propsOptions,
        oldValue: odlVal
      })
    },
    /**
     * 根据value映射到相应的label
     * @param value
     * @param options
     * @param labelKey
     * @param valueKey
     * @returns {undefined}
     */
    mapValueToLabel (value, options, labelKey = 'label', valueKey = 'value') {
      let labelValue
      if (!options) return value
      if (this.isEmpty(value)) { // 删除的时候避免value空了但是label还有值的情况
        labelValue = undefined
      } else {
        const optionItem = options.find(item => item[valueKey] === value)
        labelValue = optionItem[labelKey]
      }
      return labelValue
    },
    isEmpty: function (str) {
      return str === null || str === '' || str === undefined
    }
  }
}
