<template>
  <a-space class="toolbar">
    <slot name="toolbarRight">
      <a-icon type="reload" @click="$emit('reload')" />
      <a-dropdown>
        <a-icon type="column-height" />
        <a-radio-group
          :defaultValue="size"
          slot="overlay"
          button-style="solid"
          @change="sizeChange"
        >
          <a-radio-button value="default"> 默认 </a-radio-button>
          <a-radio-button value="middle"> 中等 </a-radio-button>
          <a-radio-button value="small"> 紧凑 </a-radio-button>
        </a-radio-group>
      </a-dropdown>
      <a-dropdown>
        <a-icon type="setting" />
        <a-checkbox-group
          :defaultValue="column1.map((item) => item.dataIndex)"
          slot="overlay"
          class="column-selector"
          @change="columnChange"
        >
          <div style="opacity: 0">占位符</div>
          <draggable
            v-model="column1"
            chosenClass="chosen"
            forceFallback="true"
            group="people"
            animation="1000"
            @end="dragend"
            class="draggable"
          >
            <a-space
              v-for="(column, index) in column1"
              :key="index"
              style="width: 100%"
            >
              <transition-group :key="column.dataIndex">
                <div :key="column.dataIndex">
                  <a-icon type="drag" style="cursor: move" />
                  <a-checkbox :value="column.dataIndex">
                    {{ column.title }}
                  </a-checkbox>
                </div>
                <!--  v-if="column.title !== '操作'" -->
                <!-- <div v-else style="visibility: hidden"></div> -->
              </transition-group>
            </a-space>
          </draggable>
        </a-checkbox-group>
      </a-dropdown>
    </slot>
  </a-space>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "toolbar",
  components: {
    draggable,
  },
  props: {
    size: {
      type: String,
    },
    myColumn: {
      type: Array,
      default: () => [],
    },
    currentColumnKeys: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    column1: {
      handler(value) {
        if (Array.isArray(value) && value.length > 0) {
          this.$emit(
            "update:currentColumnKeys",
            value.map((item) => item.dataIndex)
          );
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      column1: this.myColumn,
    };
  },
  methods: {
    // 处理原始数组删掉width为0的对象
    processingData() {
      let indexToDelete = -1;
      for (let i = 0; i < this.column1.length; i++) {
        if (this.column1[i].width === 0) {
          indexToDelete = i;
          break;
        }
      }
      // 如果找到了包含 width 为 0 的对象的下标，则删除该对象
      if (indexToDelete !== -1) {
        this.column1.splice(indexToDelete, 1);
      }
    },

    sizeChange(e) {
      this.$emit("update:size", e.target.value);
    },
    columnChange(keys) {
      this.$emit("update:currentColumnKeys", keys);
    },
    // 拖拽结束事件
    dragend(event) {
      const arr = [...this.myColumn];
      // 调换顺序
      const oldIndex = event.oldIndex; // 移动初始位置
      const newIndex = event.newIndex; // 运动终止位置
      const movedItem = arr.splice(oldIndex, 1)[0];
      arr.splice(newIndex, 0, movedItem);
      arr[oldIndex] = this.column1[oldIndex];
      arr[newIndex] = this.column1[newIndex];
      this.$emit("update:myColumn", arr);
    },
  },
  mounted() {
    setTimeout(() => {
      this.$emit(
        "update:currentColumnKeys",
        this.column1.map((item) => item.dataIndex)
      );
    }, 500);
    this.processingData();
  },
};
</script>

<style lang="less" scoped>
.toolbar {
  width: 60px;
  height: 30px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  color: #2a5cff;
  font-size: 16px;
}
.column-selector {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.35);
  width: 200px;
  /deep/.ant-dropdown-menu {
    margin-right: 4px;
  }
}
</style>
