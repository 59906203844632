<template>
  <div class="editable-cell">
    <!-- 编辑状态 -->
    <div v-if="editable" class="editable-cell-edit-wrapper">
      <!-- 单元格的编辑是下拉 -->
      <ax-select v-if="column.columnType === 'select'" v-model="value" :props-options="formItem"/>
      <!-- 单元格的编辑是开关 -->
      <ax-switch v-else-if="column.columnType === 'switch'" v-model="value" :props-options="formItem"/>
      <!-- 单元格的编辑是普通的输入框 -->
      <ax-input v-else v-model="value" :props-options="formItem" @on-input-press-enter="check" />
      <!-- 编辑后的check图标 -->
      <a-icon type="check" class="editable-cell-icon-check" @click="check"/>
    </div>
    <!-- 非编辑状态 -->
    <div v-else class="editable-cell-show-wrapper">
      <!-- 文字过长的时候可以用tooltip-->
      <a-tooltip
        v-if="column.ellipsis"
        :title="text | mapValueToLabel(column.options, column.labelKey, column.valueKey)"
        class="ax-table-cell ax-tooltip"
      >
        {{text | mapValueToLabel(column.options, column.labelKey, column.valueKey)}}
      </a-tooltip>
      <!-- 普通的展示 -->
      <div v-else class="ax-table-cell">
        {{text | mapValueToLabel(column.options, column.labelKey, column.valueKey)}}
      </div>
      <!-- 编辑的图标 -->
      <a-icon type="edit" class="editable-cell-icon-edit" @click="edit" />
    </div>
  </div>
</template>

<script>
import axSelect from './form-elements/ax-select'
import axSwitch from './form-elements/ax-switch'
import axInput from './form-elements/ax-input'
export default {
  name: 'EditableCell',
  props: ['column', 'text'],
  components: { axSelect, axSwitch, axInput },
  data () {
    return {
      editable: false, // 当前单元格是否可编辑
      value: this.text
    }
  },
  computed: {
    formItem () {
      const column = this.column
      return {
        formType: column.columnType,
        labelWidth: 0,
        size: 'small',
        colon: false,
        disabled: column.disabled,
        options: column.options,
        labelKey: column.labelKey,
        valueKey: column.valueKey,
        extraLabelKey: column.extraLabelKey,
        optionLabelProp: column.optionLabelProp,
        checkedChildren: column.checkedChildren,
        checkedValue: column.checkedValue,
        unCheckedChildren: column.unCheckedChildren,
        unCheckedValue: column.unCheckedValue
      }
    }
  },
  methods: {
    check () {
      this.editable = false
      this.$emit('on-cell-edit', this.value)
    },
    edit () {
      this.editable = true
    }
  }
}
</script>

<style lang="less" scoped>
  .editable-cell {
    &-edit-wrapper,
    &-show-wrapper{
      display: flex;
      align-items: center;
      justify-content: space-between;
      /deep/.ant-form-item{
        width: 100%;
        margin: 0;
        &-label,
        &-control{
          line-height: 22px;
        }
        &-label label::after{
          margin: 0!important;
        }
      }
    }
    &-icon-edit,
    &-icon-check{
      flex: none;
      width: 20px;
      cursor: pointer;
    }
    &-icon-edit{
      visibility: hidden;
    }
    &:hover &-icon-edit{
      visibility: visible;
    }
    &-icon-check:hover,
    &-icon-edit:hover {
      color: #108ee9;
    }
    .ax-table-cell {
      width: 100%;
      display: inline-block;
      &.ax-tooltip {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
</style>
