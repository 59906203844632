<template>
  <a-modal
    v-bind="$attrs"
    v-on="$listeners"
  >
    <ax-form
      v-bind="$attrs"
      v-on="$listeners"
      ref="moreSearchForm"
      :formBuilder="formBuilder"
      class="search-form"
      @change="onValueChange"
    >
      <template
        v-for="slotName in dynamicSlotNames"
        v-slot:[slotName]="slotProps"
      >
        <slot
          :name="slotName"
          :record="slotProps.record"
        />
      </template>
    </ax-form>

    <template slot="footer">
      <ax-button-group
        slot="extra"
        :actions="actions"
        @click="moreSearchClick"
      />
    </template>

  </a-modal>
</template>

<script>
// 计算表单的长度（恢复隐藏的菜单）
const calculateFormData = (formData = []) => {
  formData.reduce((total, item) => {
    if (total >= 24 || total + Number(item.span) > 24) {
      item.list[0].options.show = true
    }
    return total + Number(item.span)
  }, 0)
}
export default {
  name: 'MoreModal',
  props: {
    searchForm: { // 查询区域的表单
      type: Object,
      default: () => ({})
    },
    moreSearchForm: { // 更多查询区域的表单
      type: Object,
      default: () => ({})
    },
    dynamicSlotNames: { // 插槽名称
      type: Array,
      default: () => ([])
    },
    formValues: { // 表单数据的值
      type: Object
    }
  },
  data () {
    return {
      actions: [ // 操作按钮
        { text: '查询', name: 'search' },
        { text: '清空', name: 'reset', type: 'default' },
        { text: '取消', name: 'cancel', type: 'default' }
      ],
      formBuilder: {}
    }
  },
  watch: {
    searchForm: {
      handler (value) {
        if (!this.moreSearchForm?.list) {
          this.formBuilder = JSON.parse(JSON.stringify(value))
          calculateFormData(this.formBuilder?.list?.[0]?.cols)
        }
      },
      immediate: true,
      deep: true
    },
    moreSearchForm: {
      handler (value) {
        if (value?.list) {
          this.formBuilder = this.moreSearchForm
        }
      },
      immediate: true,
      deep: true
    },
    formValues: {
      handler (value) {
        if (value) {
          this.$nextTick(() => {
            this.$refs.moreSearchForm.resetFields()
            this.$refs.moreSearchForm.setFieldsValue(this.formValues)
          })
        }
      },
      deep: true
    }
  },
  methods: {
    moreSearchClick ({ btn }) {
      if (btn.name === 'reset') this.$refs.moreSearchForm.resetFields()
      const formValues = this.$refs.moreSearchForm.getFieldsValue()
      this.$emit('on-more-search-click', { formValues, btn })
    },
    onValueChange (value, params) {
      this.$emit('modal-search-change', value, params, this.$refs.moreSearchForm)
    }
  }
}
</script>

<style>
</style>
