<template>
  <div @dblclick="onClick">
    <component
      v-model="currentValue"
      :defaultValue="defaultValue"
      :is="currentInput"
      :addonAfter="propsOptions.addonAfter"
      :addonBefore="propsOptions.addonBefore"
      :disabled="propsOptions.disabled"
      :id="propsOptions.params"
      :maxLength="propsOptions.maxLength"
      :prefix="propsOptions.prefix"
      :size="propsOptions.size || size"
      :suffix="propsOptions.suffix"
      :type="propsOptions.mode"
      :allowClear="propsOptions.allowClear"

      :autoSize="propsOptions.autoSize"

      :enterButton="propsOptions.enterButton"
      :loading="propsOptions.loading"

      :visibilityToggle="propsOptions.visibilityToggle"

      @change="onChange"
      @pressEnter="onPressEnter"
      @search="onSearch"
    >
      <slot slot="addonAfter" :slotName="`addonAfter_${propsOptions.params}`"/>
      <slot slot="addonBefore" :slotName="`addonBefore_${propsOptions.params}`"/>
      <slot slot="prefix" :slotName="`prefix_${propsOptions.params}`"/>
      <slot slot="suffix" :slotName="`suffix_${propsOptions.params}`"/>
      <slot slot="enterButton" :slotName="`enterButton_${propsOptions.params}`"/>
    </component>
    <a-modal
      v-model="visible"
      :title="propsOptions.label"
      :width="800"
    >
       <a-textarea
         v-model="currentValue"
         :defaultValue="defaultValue"
         :disabled="propsOptions.disabled"
         :id="propsOptions.params"
         :autoSize="{ minRows: 16, maxRows: 16 }"
         @change="onChange"
       />
      <a-button slot="footer" type="primary" @click="visible = false">确定</a-button>
    </a-modal>
  </div>
</template>

<script>
import mixin from './mixin'
export default {
  name: 'AxInput',
  mixins: [mixin],
  data () {
    return {
      visible: false
    }
  },
  computed: {
    currentInput () {
      switch (this.mode) {
        case 'textarea':
          return 'a-textarea'
        case 'search':
          return 'a-input-search'
        case 'password':
          return 'a-input-password'
        default:
          return 'a-input'
      }
    }
  },
  methods: {
    onClick () {
      if (this.mode === 'textarea') {
        this.visible = true
      }
    },
    onChange () {
      this.$emit('change', this.currentValue)
      this.handleValueChange(this.currentValue)
    },
    onPressEnter (value, event) {
      this.$emit('on-input-press-enter', this.currentValue, this.propsOptions, event)
    },
    onSearch (value, event) {
      this.$emit('on-input-search', this.currentValue, this.propsOptions, event)
    }
  }
}
</script>
