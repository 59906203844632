<template>
  <div>
    <a-card
      v-if="isCard"
      :title="searchTitle"
      size="small"
      class="search-wrapper"
    >
      <ax-button-group slot="extra" :actions="actions" @click="searchClick">
        <template
          v-for="slotName in dynamicSlotNames"
          v-slot:[slotName]="slotProps"
        >
          <slot :name="slotName" :button="slotProps.button" />
        </template>
      </ax-button-group>
      <div class="search-form-wrapper">
        <ax-form
          v-bind="$attrs"
          v-on="$listeners"
          ref="searchForm"
          :formBuilder="searchForm"
          class="search-form"
          @change="onValueChange"
        >
          <template
            v-for="slotName in dynamicSlotNames"
            v-slot:[slotName]="slotProps"
          >
            <slot :name="slotName" :record="slotProps.record" />
          </template>
        </ax-form>

        <a-icon
          v-if="totalSpan > 24 && !isExpand"
          type="arrow-down"
          :size="30"
          :class="['toggle-icon', { open: expand }]"
          @click="expand = !expand"
        />
        <!-- <div> -->
          <!-- <ax-button-group
          :actions="defaultActions"
          slot="searchFormActions"
          @click="searchClick"
        >
          <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
            <slot v-bind="data" :name="name" />
          </template>
        </ax-button-group> -->
          <!-- <ax-button style="margin: 0 12px" @click="searchClick">查询</ax-button>
          <ax-button @click="searchClick">重置</ax-button> -->
        <!-- </div> -->
      </div>
    </a-card>

    <div
      v-else
      :class="['search-form-wrapper', { 'in-one-line': totalSpan < 24 }]"
    >
      <ax-form
        v-bind="$attrs"
        :formBuilder="searchForm"
        ref="searchForm"
        class="search-form"
        @click="searchClick"
      >
        <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
          <slot v-bind="data" :name="name" />
        </template>

        <ax-button-group
          :actions="searchActions"
          slot="searchFormActions"
          @click="searchClick"
        >
          <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
            <slot v-bind="data" :name="name" />
          </template>
        </ax-button-group>
      </ax-form>

      <a-space v-if="totalSpan >= 24" class="actions">
        <a-icon
          v-if="totalSpan > 24"
          :size="30"
          :class="['toggle-icon', { open: expand }]"
          type="arrow-down"
          @click="expand = !expand"
        />

        <ax-button-group :actions="searchActions" @click="searchClick">
          <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
            <slot v-bind="data" :name="name" />
          </template>
        </ax-button-group>
      </a-space>
    </div>
  </div>
</template>

<script>
// 计算表单的长度
const calculateFormData = (formData = [], expand) => {
  return formData.reduce((total, item) => {
    if (total >= 24 || total + Number(item.span) > 24) {
      item.list[0].options.show = expand;
    }
    return total + Number(item.span);
  }, 0);
};
// 默认操作按钮
const defaultActions = [
  { text: "查询", name: "search" },
  { text: "重置", name: "reset" },
];

export default {
  name: "AxTableSearch",
  props: {
    searchTitle: {
      type: String,
      default: "查询区域",
    },
    searchForm: {
      // 查询区域的表单
      type: Object,
      default: () => ({}),
    },
    mergeSearchActions: {
      // 是否合并查询区域按钮
      type: Boolean,
      default: false,
    },
    searchActions: {
      // 查询区域的按钮
      type: Array,
      default: () => [],
    },
    showMergeSearchButton: {
      // 是否显示更多查询按钮
      type: Boolean,
      default: false,
    },
    dynamicSlotNames: {
      // 插槽名称
      type: Array,
      default: () => [],
    },
    formValues: {
      // 表单数据的值
      type: Object,
      default: () => ({}),
    },
    isExpand: {
      type: Boolean,
      default: false,
    },
    isCard: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      expand: false,
      defaultActions,
      flag: true,
    };
  },
  computed: {
    totalSpan() {
      return this.searchForm?.list?.[0]?.cols?.reduce((total, item) => {
        // console.log(total + (item.model === 'searchFormActions' ? 0 : Number(item.span)), '-total')
        return (
          total + (item.model === "searchFormActions" ? 0 : Number(item.span))
        );
      }, 0);
    },
    actions() {
      const { mergeSearchActions, searchActions } = this;
      return mergeSearchActions
        ? [...searchActions, ...defaultActions]
        : searchActions;
    },
  },
  created() {
    ["searchForm", "expand"].forEach((prop) => {
      this.$watch(
        prop,
        () => {
          if (
            this.searchForm?.list?.[0]?.cols &&
            this.searchForm?.list?.[0]?.cols.length > 0
          ) {
            const totalSpan = calculateFormData(
              this.searchForm?.list?.[0]?.cols,
              this.expand
            );
            if (totalSpan < 24 && !this.isCard) {
              const cols = this.searchForm?.list?.[0]?.cols;
              if (
                cols[cols.length - 1]?.list?.[0].model === "searchFormActions"
              ) {
                return;
              }
              // eslint-disable-next-line no-unused-expressions
              this.searchForm?.list?.[0]?.cols.push(
                this.$ax.tools.generateFormItem({
                  model: "searchFormActions",
                  span: 24 - totalSpan,
                })
              );
            }
          }
        },
        { immediate: true, deep: true }
      );
    });
  },
  watch: {
    formValues: {
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            this.$refs.searchForm.resetFields();
            this.$refs.searchForm.setFieldsValue(this.formValues);
          });
        }
      },
      deep: true,
    },
    isExpand: {
      handler(value) {
        this.expand = value;
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.showMergeSearchButton)
      this.actions.push({ text: "更多查询", name: "moreSearch" });
  },
  methods: {
    searchClick({ btn }) {
      if (btn.name === "reset") this.$refs.searchForm.resetFields();
      const formValues = this.$refs.searchForm.getFieldsValue();
      this.$emit("on-search-click", { formValues, btn });
    },
    onValueChange(value, params) {
      this.$emit("search-change", value, params, this.$refs.searchForm);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.search-form-wrapper.in-one-line{
  background-color: #F9FAFC;
  border-radius: 6px;
  border-bottom: 1px solid #D8DDEA;
}
</style>
