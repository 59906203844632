<template>
  <a-select
    v-model="currentValue"
    :defaultValue="defaultValue"
    :allowClear="propsOptions.allowClear"
    :autoClearSearchValue="propsOptions.autoClearSearchValue"
    :autoFocus="propsOptions.autoFocus"
    :defaultActiveFirstOption="propsOptions.defaultActiveFirstOption"
    :disabled="propsOptions.disabled"
    :dropdownClassName="propsOptions.dropdownClassName"
    :dropdownMatchSelectWidth="false"
    :dropdownRender="propsOptions.dropdownRender"
    :dropdownStyle="propsOptions.dropdownStyle"
    :dropdownMenuStyle="propsOptions.dropdownMenuStyle"
    :filterOption="propsOptions.filterOption || filterOption"
    :firstActiveValue="propsOptions.firstActiveValue"
    :getPopupContainer="propsOptions.getPopupContainer"
    :maxTagCount="propsOptions.maxTagCount"
    :maxTagPlaceholder="propsOptions.maxTagPlaceholder"
    :maxTagTextLength="propsOptions.maxTagTextLength"
    :mode="mode"
    :notFoundContent="propsOptions.notFoundContent"
    :optionFilterProp="propsOptions.optionFilterProp"
    :optionLabelProp="propsOptions.optionLabelProp"
    :placeholder="propsOptions.placeholder"
    :showSearch="propsOptions.showSearch || true"
    :showArrow="propsOptions.showArrow"
    :size="propsOptions.size || size"
    :suffixIcon="propsOptions.suffixIcon"
    :removeIcon="propsOptions.removeIcon"
    :clearIcon="propsOptions.clearIcon"
    :menuItemSelectedIcon="propsOptions.menuItemSelectedIcon"
    :tokenSeparators="propsOptions.tokenSeparators"
    :defaultOpen="propsOptions.defaultOpen"

    style="width: 100%"

    @change="onChange"
    @blur="onBlur"
    @deselect="onDeselect"
    @focus="onFocus"
    @inputKeydown="onInputKeydown"
    @mouseenter="onMouseenter"
    @mouseleave="onMouseleave"
    @popupScroll="onPopupScroll"
    @search="onSearch"
    @select="onSelect"
    @dropdownVisibleChange="onDropdownVisibleChange"
  >
    <a-select-option
      v-for="(item, index) in propsOptions.options"
      :key="index"
      :disabled="item.disabled"
      :class="item.class"
      :value="item[valueKey]"
      :label="item[extraLabelKey]"
    >{{item[labelKey]}}
    </a-select-option>
  </a-select>
</template>

<script>
import mixin from './mixin'

const getSelectValue = (mode, value = '', type = 'toArr') => {
  return ['tags', 'multiple'].indexOf(mode) > -1 ? this.$ax.tools.formatValue(value, type) : value && value.replace(/\s/g, '')
}
export default {
  name: 'AxSelect',
  mixins: [mixin],
  data () {
    return {
      currentValue: getSelectValue(this.mode, this.value),
      defaultValue: getSelectValue(this.mode, this.value)
    }
  },
  watch: {
    value: {
      handler (value) {
        this.currentValue = getSelectValue(this.mode, value)
        this.handleValueChange(this.currentValue)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onChange (value) {
      const tempValue = getSelectValue(this.mode, value, 'toStr')
      this.$emit('change', tempValue)
      this.handleValueChange(value)
    },
    /**
     * 失去焦点的时回调
     */
    onBlur () {
      this.$emit('on-select-blur', this.currentValue, this.propsOptions)
    },
    /**
     *`取消选中时调用，参数为选中项的 value (或 key) 值，仅在 multiple 或 tags 模式下生效
     * @param value
     * @param option
     */
    onDeselect (value, option) {
      this.$emit('on-select-deselect', this.currentValue, this.propsOptions, option)
    },
    /**
     * 获得焦点时回调
     */
    onFocus () {
      this.$emit('on-select-focus', this.currentValue, this.propsOptions)
    },
    /**
     * 键盘按下时回调
     */
    onInputKeydown () {
      this.$emit('on-select-input-keydown', this.currentValue, this.propsOptions)
    },
    /**
     * 鼠标移入时回调
     */
    onMouseenter () {
      this.$emit('on-select-mouseenter', this.currentValue, this.propsOptions)
    },
    /**
     *鼠标移出时回调
     */
    onMouseleave () {
      this.$emit('on-select-mouseleave', this.currentValue, this.propsOptions)
    },
    /**
     *下拉列表滚动时的回调
     */
    onPopupScroll () {
      this.$emit('on-select-popup-scroll', this.currentValue, this.propsOptions)
    },
    /**
     * 文本框值变化时回调
     */
    onSearch () {
      this.$emit('on-select-search', this.currentValue, this.propsOptions)
    },
    /**
     * 被选中时调用，参数为选中项的 value (或 key) 值
     * @param value
     * @param option
     */
    onSelect (value, option) {
      this.$emit('on-select-select', this.currentValue, this.propsOptions, option)
    },
    /**
     * 展开下拉菜单的回调
     * @param open
     */
    onDropdownVisibleChange (open) {
      this.$emit('on-select-dropdown-visible-change', this.currentValue, this.propsOptions, open)
    },
    /**
     * 是否根据输入项进行筛选。
     * @param
     */
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>
