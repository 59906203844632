import Vue from 'vue'
import VueDraggableResizable from 'vue-draggable-resizable'

Vue.component('vue-draggable-resizable', VueDraggableResizable)

let arr = []

const delData = (data, name) => {
  for (let i = 0; i < data.length; i++) {
    if (!data[i][name]) {
      arr.push(data[i])
      data.splice(i, 1)
      return delData(data, name)
    }
  }
}

export default {
  props: {
    showSearch: { // 是否显示查询区域
      type: Boolean,
      default: true
    },
    rowKey: { // table的rowKey
      type: String,
      default: 'id'
    },
    columns: { // 表头
      type: Array,
      default: () => ([])
    },
    autoLoad: { // 是否自动加载表格数据
      type: Boolean,
      default: true
    },
    dataSourceApi: { // 查询表格数据的api
      type: Function,
      default: () => new Promise(resolve => { })
    },
    dataSourceParams: { // 查询表格数据的参数
      type: Object,
      default: () => ({})
    },
    sqlParams: { // sql查询
      type: Object,
      default: () => ({})
    },
    handleQueryParams: { // 对查询参数做额外的处理
      type: Function,
      default: args => args
    },
    handleDataSource: { // 处理接口返回的数据，一般用于接口返回数据结构特殊的时候
      type: Function
    },
    clickable: { // 是否开启点击效果
      type: Boolean,
      default: false
    },
    pagination: { // 分页参数，为false时不展示分页
      type: [Object, Boolean],
      default: () => ({
        current: 1,
        defaultCurrent: 1,
        pageSize: 10,
        defaultPageSize: 10,
        pageSizeOptions: ['10', '20', '30', '40'],
        showSizeChanger: true,
        total: 0,
        showTotal: total => `共${total}条`,
        hideOnSinglePage: false
      })
    },
    replacePagination: {
      type: [Object],
      default: null
    },
    rowClassName: { // 表格行的类名
      type: Function
    },
    showZebra: { // 是否开启表格斑马纹样式
      type: Boolean,
      default: true
    },
    customRow: { // 设置行属性
      type: Function
    },
    dynamicSlotNames: { // 查询区域表单控件的插槽名称
      type: Array,
      default: () => ([])
    },

    readonly: { // 表单是否只读（不可行内编辑，也不可以单元格编辑）
      type: Boolean,
      default: true
    },
    editInRow: { // 是否开启行内编辑
      type: Boolean,
      default: false
    },
    sorterApi: { // sorter的时候是否调用api
      type: Boolean,
      default: false
    }
  },
  data() {
    this.components = {
      header: {
        cell: (h, props, children) => {
          const { key, ...restProps } = props

          const col = this.columns.find(col => {
            const k = col.dataIndex || col.key
            return k === key
          })

          if (!col || !col.width || (typeof col.width === 'string')) {
            return h('th', { ...restProps }, [...children])
          }

          if (col.fixed) {
            return h('th', { ...restProps }, [...children])
          }

          const dragProps = {
            key: col.dataIndex || col.key,
            class: 'table-draggable-handle',
            attrs: {
              w: 10,
              x: col.width,
              z: 1,
              axis: 'x',
              draggable: true,
              resizeable: true
            },
            on: {
              dragging: (x, y) => {
                col.width = Math.max(x, 1)
              }
            }
          }
          const drag = h('vue-draggable-resizable', { ...dragProps })
          return h('th', { ...restProps, class: 'resize-table-th' }, [...children, drag])
        }
      }
    }
    return {
      dataSource: [],
      loading: false,
      params: {},
      currentRowIndex: null,
      currentIndex: 0, // table当前行的索引，默认是第一条
      formValues: {},
      editRowIndex: null, // 当前编辑的行
      showSearchMoadal: false // 显示更多查询弹框
    }
  },
  created() {
    Object.assign(this.pagination, this.$ax.config?.tableProps?.pagination, this.replacePagination)
    if (this.autoLoad) this.getDataSource()
  },
  computed: {
    currentRowData: { // 当前行的数据
      get: function () {
        if (this.dataSource.length - 1 < this.currentIndex) this.currentIndex = 0
        return this.dataSource[this.currentIndex]
      },
      set: function (newVal) {
        this.dataSource[this.currentIndex] = newVal
      }
    }
  },
  methods: {
    /**
     * 获取表格数据
     * @param args
     */
    getDataSource(args) {
      this.loading = true
      const params = this.$ax.tools.formatQueryParams(this.sqlParams, {
        ...this.dataSourceParams,
        ...this.formValues
      })
    
      const { pagination = {}, handleDataSource } = { ...this.$ax.config?.tableProps, ...this.replacePagination }
      const { currentKey = 'pageNo', pageSizeKey = 'pageSize' } = this.replacePagination || pagination
      params[currentKey] = this.pagination.current
      params[pageSizeKey] = this.pagination.pageSize
        // 如果一个对象里面有某个属性的属性值是一个数组就把这个属性的属性值变成这个数组的最后一个数据 并重新变成一个新对象 
      const transformObject = (obj) => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          acc[key] = Array.isArray(value) ? value[value.length - 1] : value;
          return acc;
        }, {});
      }
      this.params = transformObject(params)

      this.dataSourceApi(this.handleQueryParams({
        ...this.params,
        ...args
      })).then(res => {
        if (typeof this.handleDataSource === 'function') {
          this.handleDataSource(this, res)
        } else if (handleDataSource) {
          handleDataSource(this, res)
        } else {
          const { records = [], total = 0, current = 1 } = res.data
          this.dataSource = records
          if (this.pagination) {
            this.pagination.current = current
            this.pagination.total = total
          }
          if (this.clickable) this.currentRowIndex = 0
          this.loading = false
          this.$emit('data-source-loaded', { dataSource: this.dataSource, response: res })
        }
      }).catch(e => {
        this.loading = false
        console.log(e)
      })
    },
    /**
     * 点击分页控件时触发
     * @param pagination
     */
    paginationChange(page, pageSize) {
      this.pagination.current = page
      this.pagination.pageSize = pageSize
      this.getDataSource()
    },
    showSizeChange(current, size) {
      this.pagination.current = 1
      this.pagination.pageSize = size
      this.getDataSource()
    },
    /**
     * 点击表格筛选时触发
     * @param pagination
     */
    tableChange(pagination, filters, sorter, { currentDataSource }) {
      let params = {}
      arr = []
      if (!sorter?.order && !this.sorterApi) return this.getDataSource()
      if (sorter?.order && !this.sorterApi) {
        if (typeof sorter?.column?.sorter === 'function') {
          this.dataSource = currentDataSource.sort(sorter?.column?.sorter)
        } else {
          delData(currentDataSource, sorter?.field)
          if (sorter.order === 'ascend') {
            this.dataSource = currentDataSource.sort((a, b) => {
              for (var i = 0; i < a[sorter?.field].length; i++) {
                if (a[sorter?.field].charCodeAt(i) === b[sorter?.field].charCodeAt(i)) continue
                return a[sorter?.field].charCodeAt(i) - b[sorter?.field].charCodeAt(i)
              }
            })
            if (arr.length > 0) {
              arr.forEach(item => this.dataSource.push(item))
            }
          } else if (sorter.order === 'descend') {
            this.dataSource = currentDataSource.sort((a, b) => {
              for (var i = 0; i < b[sorter?.field].length; i++) {
                if (b[sorter?.field].charCodeAt(i) === a[sorter?.field].charCodeAt(i)) continue
                return b[sorter?.field].charCodeAt(i) - a[sorter?.field].charCodeAt(i)
              }
            })
            if (arr.length > 0) {
              arr.forEach(item => this.dataSource.unshift(item))
            }
          }
        }
        return this.dataSource.concat
      }
      if (sorter?.order && this.sorterApi) {
        params = {
          column: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'asc' : 'desc'
        }
        return this.getDataSource(params)
      }
    },
    /**
     * 设置行属性
     * @param record
     * @param index
     * @returns {{on: {dblclick: on.dblclick}}}
     */
    axCustomRow(record, index) {
      if (typeof this.customRow === 'function') return this.customRow(record, index)
      return {
        on: {
          click: () => {
            if (this.clickable) this.currentRowIndex = index
            const preRowData = this.currentRowData
            this.editRowIndex = index
            this.currentIndex = index
            this.$emit('click', { record, index, preRowData })
          },
          dblclick: () => {
            if (this.clickable) this.currentRowIndex = index
            this.currentIndex = index
            this.$emit('dblclick', { record, index })
          }
        }
      }
    },
    /**
     * 点击单元格触发
     * @param column
     * @param record
     */
    onCellClick(column, record) {
      this.$emit('cell-click', { column, record })
    },
    /**
     * 编辑单元格(保存)
     * @param value 单元格的新值
     * @param dataIndex 单元格列对应的dataIndex
     * @param record 单元格所在行的数据
     * @param rowIndex 行索引
     * @param columnIndex 列索引
     */
    onCellEdit(value, dataIndex, record, rowIndex, columnIndex) {
      this.currentRowData[dataIndex] = value
      this.$emit('cell-edit-save', { value, dataIndex, currentRowData: this.currentRowData, rowIndex, columnIndex })
    },
    /**
     * 设置行的样式类
     * @param record
     * @param index
     * @returns {string}
     */
    axRowClassName(record, index) {
      if (typeof this.rowClassName === 'function') return this.rowClassName(record, index)
      if (this.showZebra) return this.currentRowIndex ? (index === this.currentRowIndex && index % 2 === 1 ? 'current-row' : index === this.currentRowIndex && index % 2 === 1 ? 'current-row' : index % 2 === 1 ? 'current-Zebra-row' : index === this.currentRowIndex && index % 2 === 0 ? 'current-row' : '') : index === this.currentRowIndex && index === 0 ? 'current-row' : index % 2 === 1 ? 'current-Zebra-row' : ''
      return index === this.currentRowIndex ? 'current-row' : ''
    },
    /**
     * 点击查询区域按钮时触发
     * @param formValues
     * @param btn
     */
    searchClick({ formValues, btn }) {
      this.formValues = { ...this.formValues, ...formValues }
      if (['search', 'reset'].includes(btn.name)) {
        if (this.pagination) {
          this.pagination.current = 1
        }
        this.getDataSource()
      }
      if (['moreSearch'].includes(btn.name)) {
        this.showSearchMoadal = true
      }
      this.$emit('search-click', { formValues, btn })
      this.$emit(btn.name, { formValues, btn })
    },

    toolClick({ formValues, btn }) {
      // console.log(formValues, '346');
      // 导入
      if (['inport'].includes(btn.name)) {
        this.$emit('inport', { formValues, btn })
      }
      // 导出筛选
      if (['export'].includes(btn.name)) {
        this.$emit('export', { formValues, btn })
      }
      // 导出全部
      if (['exportAll'].includes(btn.name)) {
        this.$emit('exportAll', { formValues, btn })
      }
      // 新增
      if (['add'].includes(btn.name)) {
        this.$emit('add', { formValues, btn })
      }
      // 批量删除
      if (['bulkDeletion'].includes(btn.name)) {
        this.$emit('bulkDeletion', { formValues, btn })
      }
    },
    // 更多查询弹框取消按钮
    handleCancel() {
      this.showSearchMoadal = false
    },
    // 点击更多查询弹框里面按钮时触发
    moreSearchClick({ formValues, btn }) {
      this.formValues = { ...this.formValues, ...formValues }
      if (['cancel'].includes(btn.name)) {
        this.showSearchMoadal = false
      }
      if (['search', 'reset'].includes(btn.name)) {
        this.showSearchMoadal = false
        if (this.pagination) {
          this.pagination.current = 1
        }
        this.getDataSource({ pageNo: 1 })
      }
      this.$emit('search-click', { formValues, btn })
      this.$emit(btn.name, { formValues, btn })
    },
    /**
     * 确认气泡点击取消的回调
     * @param record
     * @param btn
     */
    popCancel({ record, btn }) {
      this.$emit('pop-cancel', { record, btn })
    },
    /**
     * 确认气泡点击确认的回调
     * @param record
     * @param btn
     */
    popConfirm({ record, btn }) {
      this.$emit('pop-confirm', { record, btn })
    },
    /**
     * 没有确认气泡时点击操作列时触发
     * @param record
     * @param btn
     */
    actionColumnClick({ record, btn }) {
      btn.popDisabled && this.$emit('action-column-click', { record, btn })
    },
    handleClickOutside() {
      this.editRowIndex = null
      this.$emit('click-table-side', this.currentRowData)
    },
    tableValueChange(value, column) {
      this.$emit('table-value-change', value, column)
    }
  }
}
