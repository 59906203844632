const clickoutsideContext = '@@clickoutsideContext'

export default {
  bind (el, binding, vnode) {
    const documentHandler = (e) => {
      if (vnode.context && (!e.target.classList.contains('ax-table-cell') && !e.target.classList.contains('ant-select-dropdown-menu-item') && !el.contains(e.target))) {
      // if (vnode.context && !el.contains(e.target)) {
        vnode.context[el[clickoutsideContext].methodName]()
      }
    }
    el[clickoutsideContext] = {
      documentHandler,
      methodName: binding.expression,
      arg: binding.arg || 'click'
    }
    document.addEventListener(el[clickoutsideContext].arg, documentHandler)
  },

  update (el, binding) {
    el[clickoutsideContext].methodName = binding.expression
  },

  unbind (el) {
    document.removeEventListener(
      el[clickoutsideContext].arg,
      el[clickoutsideContext].documentHandler)
  },

  install (Vue) {
    Vue.directive('clickoutside', {
      bind: this.bind,
      unbind: this.unbind
    })
  }
}
