<template>
  <a-switch
    v-model="currentValue"
    :defaultChecked="defaultValue"
    :autoFocus="propsOptions.autoFocus"
    :checkedChildren="propsOptions.checkedChildren"
    :disabled="propsOptions.disabled"
    :loading="propsOptions.loading"
    :size="propsOptions.size || size"
    :unCheckedChildren="propsOptions.unCheckedChildren"
    @change="onChange"
    @click="onClick"
  />
</template>

<script>
import mixin from './mixin'
const getCheckValue = (value, checkedValue) => {
  if (checkedValue) return value === checkedValue
  else return value
}
export default {
  name: 'AxSwitch',
  mixins: [mixin],
  data () {
    return {
      currentValue: getCheckValue(this.value, this.propsOptions.checkedValue),
      defaultValue: getCheckValue(this.value, this.propsOptions.checkedValue)
    }
  },
  watch: {
    value (value) {
      this.currentValue = getCheckValue(value, this.propsOptions.checkedValue)
      this.handleValueChange(value)
    }
  },
  methods: {
    onChange (checked) {
      if (this.propsOptions.checkedValue && this.propsOptions.unCheckedValue) {
        const value = checked ? this.propsOptions.checkedValue : this.propsOptions.unCheckedValue
        this.$emit('change', value)
        this.handleValueChange(value)
      } else {
        this.$emit('change', checked)
        this.handleValueChange(checked)
      }
    },
    /**
     *点击时回调函数
     * @param checked
     * @param event
     */
    onClick (checked, event) {
      this.$emit('on-switch-click', checked, this.propsOptions, event)
    }
  }
}
</script>
