<template>
  <div class="ax-table">
    <!-- 模糊查询区 -->
    <ax-table-search
      v-if="showSearch"
      ref="tableSearch"
      v-bind="$attrs"
      v-on="$listeners"
      :formValues="formValues"
      :dynamicSlotNames="dynamicSlotNames"
      :isCard="icard"
      :searchActions="searchActions"
      @on-search-click="searchClick"
    >
      <template
        v-for="slotName in dynamicSlotNames"
        v-slot:[slotName]="slotProps"
      >
        <slot
          :name="slotName"
          :record="slotProps.record"
          :button="slotProps.button"
        />
      </template>
    </ax-table-search>
    <!-- 功能按钮区 -->
    <div style="display: flex; justify-content: space-between">
      <!-- 功能按钮区 -->
      <div class="table-toolbtns" v-show="isShowToolBtn">
        <ax-button-group v-on="$listeners" :actions="toolActions" v-bind="$attrs" @click="toolClick" >
          <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
            <slot v-bind="data" :name="name" />
          </template>
        </ax-button-group>
      </div>
      <!-- 列设置区 -->
      <div class="table-toolbars" v-show="isShowToolBar">
        <toolbar
          :size.sync="size"
          :myColumn.sync="myColumn"
          :currentColumnKeys.sync="currentColumnKeys"
          @reload="getDataSource({}, true)"
        >
          <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
            <slot v-bind="data" :name="name" />
          </template>
        </toolbar>
      </div>
    </div>
    <!-- 表格 -->
    <a-table
      v-bind="$attrs"
      :components="components"
      :rowKey="rowKey"
      :columns="
        myColumn.filter((item) => currentColumnKeys.includes(item.dataIndex))
      "
      :dataSource="dataSource"
      :pagination="false"
      :loading="loading"
      :bordered="bordered"
      :size="size"
      :customRow="axCustomRow"
      :rowClassName="axRowClassName"
      v-clickoutside="handleClickOutside"
      @change="tableChange"
    >
      <template
        v-for="(column, columnIndex) in myColumn"
        v-slot:[slotName(column)]="text, record, index"
      >
        <div :key="columnIndex" :class="column.cellClass">
          <slot
            :name="column.dataIndex"
            :column="column"
            :record="record"
            :text="text"
            :index="index"
            :column-index="columnIndex"
            :current-row-data="currentRowData"
          >
            <!-- 序号列 -->
            <template v-if="column.dataIndex === 'orderColumn'">
              {{ tableOrder(index, column) }}
            </template>

            <!-- 操作列 -->
            <ax-button-group
              v-else-if="column.dataIndex === 'actionColumn'"
              :key="column.dataIndex"
              @click.native.stop
            >
              <template
                v-for="(btn, btnIndex) in columnActions(record, column.actions)"
              >
                <slot
                  v-if="!btnProps(btn, record).hide"
                  :name="btnProps(btn, record).name"
                  :btn="btnProps(btn, record)"
                  :record="record"
                >
                  <a-popconfirm
                    v-bind="btnProps(btn, record)"
                    :key="btnIndex"
                    :disabled="btnProps(btn, record).popDisabled"
                    @cancel="popCancel({ record, btn: btnProps(btn, record) })"
                    @confirm="
                      popConfirm({ record, btn: btnProps(btn, record) })
                    "
                  >
                    <ax-button
                      v-bind="btnProps(btn, record)"
                      :title="undefined"
                      @click="
                        actionColumnClick({
                          record,
                          btn: btnProps(btn, record),
                        })
                      "
                    />
                  </a-popconfirm>
                </slot>
              </template>
            </ax-button-group>

            <!-- 可编辑的行 -->
            <editable-row
              v-else-if="
                !readonly && editInRow && column.dataIndex !== 'actionColumn'
              "
              v-model="currentRowData[column.dataIndex]"
              :text="text"
              :column="column"
              :editable="editRowIndex === index"
              @change="tableValueChange"
            />
            <!-- 可编辑的单元格 -->
            <editable-cell
              v-else-if="
                !readonly &&
                column.editableCell &&
                column.dataIndex !== 'actionColumn'
              "
              :text="text"
              :column="column"
              @on-cell-edit="
                onCellEdit($event, column.dataIndex, record, index, columnIndex)
              "
            />
            <slot
              v-else
              v-bind="{ column, record, text, index }"
              :name="column.dataIndex"
            >
              <component
                :is="column.component || 'span'"
                :key="column.dataIndex"
                :title="text | globalFilter(column.filter, { column, record })"
                :style="
                  text
                    | globalFilter(column.filter, {
                      column,
                      record,
                      type: 'style',
                    })
                "
              >
                <template v-if="column.filter">
                  <div
                    :class="{ ellipsis: column.component === 'a-tooltip' }"
                    v-html="
                      text | globalFilter(column.filter, { column, record })
                    "
                    @click="onCellClick(column, record)"
                  />
                </template>
                <div v-else>
                  <!-- {{ typeof column.width === 'number' ? `${column.width}px` : column.width }} -->
                  <div
                    :class="{ ellipsis: column.component === 'a-tooltip' }"
                    v-html="text"
                    @click="onCellClick(column, record)"
                  />
                </div>
              </component>
            </slot>
          </slot>
        </div>
      </template>

      <template v-for="(column, index) in myColumn">
        <div :slot="`${column.dataIndex}Title`" :key="index">
          <slot :name="`${column.dataIndex}Title`"></slot>
        </div>
      </template>
    </a-table>
    <!-- 分页 -->
    <a-pagination
      v-if="pagination"
      size="small"
      style="text-align: right; margin: 16px 0"
      show-size-changer
      :current="pagination.current"
      :showQuickJumper="pagination.showQuickJumper"
      :defaultCurrent="pagination.defaultCurrent"
      :hideOnSinglePage="pagination.hideOnSinglePage"
      :pageSize="pagination.pageSize"
      :defaultPageSize="pagination.defaultPageSize"
      :pageSizeOptions="pagination.pageSizeOptions"
      :showSizeChanger="pagination.showSizeChanger"
      :total="pagination.total"
      :show-total="pagination.showTotal"
      @change="paginationChange"
      @showSizeChange="showSizeChange"
    />
    <!-- 更多查询 -->
    <more-modal
      v-bind="$attrs"
      v-on="$listeners"
      title="更多查询"
      width="60%"
      forceRender
      :visible="showSearchMoadal"
      :dynamicSlotNames="dynamicSlotNames"
      :formValues="formValues"
      @cancel="handleCancel"
      @on-more-search-click="moreSearchClick"
    >
      <template
        v-for="slotName in dynamicSlotNames"
        v-slot:[slotName]="slotProps"
      >
        <slot
          :name="slotName"
          :record="slotProps.record"
          :button="slotProps.button"
        />
      </template>
    </more-modal>
  </div>
</template>

<script>
import mixin from "./mixin";
import editableRow from "./components/editable-row";
import editableCell from "./components/editable-cell";
import moreModal from "./components/more-modal.vue";
import clickoutside from "./clickoutside";
import Vue from "vue";
import toolbar from "./components/toolbar";
import axTableSearch from "../../ax-table-search";

Vue.use(clickoutside);
const searchActions = [
  { name: "search", text: "查询" },
  { name: "reset", text: "重置" },
];
export default {
  name: "axTable",
  components: { axTableSearch, editableRow, editableCell, moreModal, toolbar },
  mixins: [mixin],
  props: {
    size: {
      // 表格大小
      type: String,
      default: "small",
    },
    bordered: {
      // 表格是否带边框
      type: Boolean,
      default: true,
    },
    tableActions: {
      type: Array,
      default: () => [],
    },
    searchActions: {
      // 查询区域的按钮
      type: Array,
      default: () => searchActions,
    },
    toolActions: {
      // 功能区域的按钮
      type: Array,
      default: () => [],
    },
    isCard: {
      type: Boolean,
      default: null,
    },
    headerTitle: {
      type: String,
      default: "",
    },
    showToolBar: {
      type: Boolean,
      default: null,
    },
    showToolBtn: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      currentColumnKeys: [],
      myColumn: [],
      // icard:false,
    };
  },
  mounted() {},
  watch: {
    columns: {
      handler(value) {
        if (value) {
          this.myColumn = [...value];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    /**
     * 如果column宽度设置了自动省略，则此处不使用插槽
     * 因为如果使用插槽了此处的自动省略鼠标效果将会失效
     * @returns {Function}
     */
    slotName() {
      return (column) => {
        if (
          !this.readonly ||
          !column.ellipsis ||
          column.dataIndex === "orderColumn"
        )
          return column.dataIndex;
      };
    },
    /**
     * 表格序号的值
     * @returns {Function}
     */
    tableOrder() {
      return (index, column) => {
        if (column.showOrder === "accumulation" && this.pagination) {
          const { current, pageSize } = this.pagination;
          return (current - 1) * pageSize + index + 1;
        } else return index + 1;
      };
    },
    columnActions() {
      return (record, actions) => {
        return typeof actions === "function" ? actions(record) : actions;
      };
    },
    /**
     * 操作列按钮的属性
     * @returns {function(*, *=): {size: string}}
     */
    btnProps() {
      return (btn, record) => {
        return {
          size: "small",
          popDisabled: true,
          title: "是否确定？",
          cancelText: "否",
          okText: "是",
          ...btn,
          ...btn?.props?.(record),
        };
      };
    },
    /*
     */
    icard() {
      // console.log(this.isCard, this.$ax.config?.tableProps?.isCard, 'this.isCard')
      if (this.isCard === true || this.isCard === false) {
        return this.isCard;
      }
      if (
        this.$ax.config?.tableProps?.isCard === true ||
        this.$ax.config?.tableProps?.isCard === false
      ) {
        return this.$ax.config?.tableProps?.isCard;
      }
      return true;
    },
    showBar() {
      return this.$ax.config?.tableProps?.showBar;
    },

    isShowToolBar() {
      if (this.showToolBar === true || this.showToolBar === false) {
        return this.showToolBar;
      }
      if (
        this.$ax.config?.tableProps?.showToolBar === true ||
        this.$ax.config?.tableProps?.showToolBar === false
      ) {
        return this.$ax.config?.tableProps?.showToolBar;
      }
      return true;
    },
    isShowToolBtn() {
      if (this.showToolBtn === true || this.showToolBtn === false) {
        return this.showToolBtn;
      }
      if (
        this.$ax.config?.tableProps?.showToolBtn === true ||
        this.$ax.config?.tableProps?.showToolBtn === false
      ) {
        return this.$ax.config?.tableProps?.showToolBtn;
      }
      return true;
    },
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
}
/deep/.ant-card {
  border-radius: 4px;
}
/deep/.ant-card-head {
  border-radius: 4px;
  background-color: #fafafa;
}
/deep/.search-form-wrapper.in-one-line {
  border-radius: 4px;
  background-color: #fafafa;
  border-bottom: 1px solid #d8ddea;
}
</style>
