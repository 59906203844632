<template>
  <div class="editable-row">
    <!-- 编辑状态 -->
    <div v-if="editable && column.dataIndex !== orderKey">
      <!-- 单元格的编辑是下拉 -->
      <ax-select v-if="column.columnType === 'select'" v-model="currentValue" :props-options="formItem" @on-value-change="onValueChange"/>
      <!-- 单元格的编辑是开关 -->
      <ax-switch v-else-if="column.columnType === 'switch'" v-model="currentValue" :props-options="formItem" @on-value-change="onValueChange"/>
      <!-- 单元格的编辑是普通的输入框 -->
      <ax-input v-else v-model="currentValue" :props-options="formItem" @on-value-change="onValueChange"/>
    </div>
    <!-- 非编辑状态 -->
    <div v-else>
      <!-- 文字过长的时候可以用tooltip-->
      <a-tooltip
        v-if="column.ellipsis"
        :title="text | mapValueToLabel(column.options, column.labelKey, column.valueKey)"
        class="ax-table-cell ax-tooltip"
      >
        {{text | mapValueToLabel(column.options, column.labelKey, column.valueKey)}}
      </a-tooltip>
      <!-- 普通的展示 -->
      <div v-else class="ax-table-cell">
        {{text | mapValueToLabel(column.options, column.labelKey, column.valueKey)}}
      </div>
    </div>
  </div>
</template>
<script>
import axSelect from './form-elements/ax-select'
import axSwitch from './form-elements/ax-switch'
import axInput from './form-elements/ax-input'
export default {
  name: 'EditableRow',
  props: ['value', 'text', 'orderKey', 'column', 'editable'],
  model: {
    prop: 'value',
    event: 'change'
  },
  components: { axSelect, axSwitch, axInput },
  data () {
    return {
      currentValue: this.value
    }
  },
  computed: {
    formItem () {
      const column = this.column
      return {
        formType: column.columnType,
        labelWidth: 0,
        size: 'small',
        colon: false,
        options: column.options,
        disabled: column.disabled,
        labelKey: column.labelKey,
        valueKey: column.valueKey,
        extraLabelKey: column.extraLabelKey,
        optionLabelProp: column.optionLabelProp,
        checkedChildren: column.checkedChildren,
        checkedValue: column.checkedValue,
        unCheckedChildren: column.unCheckedChildren,
        unCheckedValue: column.unCheckedValue
      }
    }
  },
  watch: {
    value: {
      handler (value) {
        this.currentValue = value
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onValueChange () {
      this.$emit('change', this.currentValue, this.column)
    }
  }
}
</script>

<style lang="less" scoped>
  .editable-row {
    /deep/.ant-form-item{
      width: 100%;
      margin: 0;
      &-label,
      &-control{
        line-height: 22px;
      }
      &-label label::after{
        margin: 0!important;
      }
    }
  }
  .ax-table-cell {
    width: 100%;
    display: inline-block;
    &.ax-tooltip {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>
